<template>
  <div>
    <!-- breadcrumb面包屑导航区域 -->
    <!-- 首页/活动管理/活动列表/活动详情 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">主页</el-breadcrumb-item>
      <el-breadcrumb-item>订单管理</el-breadcrumb-item>
      <el-breadcrumb-item>订单设置</el-breadcrumb-item>
    </el-breadcrumb>

    <el-card body-style=" padding: 30px;">
      <div class="text">
        <p>
          待付款订单失效时间设置:
          <el-input
            placeholder="请输入"
            v-model="OrderOutTime"
            clearable
            style="width: 150px"
          >
          </el-input>
          分内未付款，则会自动取消订单。
        </p>
        <p class="text1">
          退单时限设置:
          <el-input
            placeholder="请输入"
            v-model="OrderOutDAY"
            clearable
            style="width: 150px"
          >
          </el-input
          >分后不允许申请退单。
        </p>
        <!-- <el-button round>保存</el-button> -->
      </div>
    </el-card>
  </div>
</template>
<script>
export default {
  data () {
    return {
      OrderOutTime: '',
      OrderOutDAY: '',
    }
  }
}
</script>
<style lang="less" scoped>
.text {
  text-align: left;
  margin-left: 200px;
  font-size: 16px;
}
.text1 {
  margin-top: 50px;
}
</style>